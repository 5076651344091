import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBImageWithText = ZSCBBase.omit({
  background_variant: true,
}).extend({
  type: z.literal(ESuluContentBlockTypes.IMAGE_WITH_TEXT),
  image: ZSuluMedia,
  image_title: z.string().or(z.null()),
  image_description: z.string().or(z.null()),
  button_link: z.string().or(z.null()),
  button_text: z.string(),
});

export default ZSCBImageWithText;
