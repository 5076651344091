import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowScroll } from 'react-use';

import LogoWhite from 'assets/logos/logo_white.svg';

import BlockContainer from 'components/blocks/_blockContainer';
import LanguageSwitch from 'components/static/LanguageSwitch';

import { INavigation } from 'types/interfaces/sulu/INavigation';

import getCurrentLanguage from 'utils/sulu/getCurrentLanguage';

interface IMenu {
  menuItems: INavigation[];
}

const isActive = (menuItem: INavigation): boolean =>
  window.location.pathname === `/${menuItem.locale}${menuItem.url}`;

export default ({ menuItems }: IMenu) => {
  const { t } = useTranslation();
  const { y: windowScrollY } = useWindowScroll();

  const navigateToHome = () => {
    window.location.href = `/${getCurrentLanguage}`;
  };

  const menuClassName =
    'flex items-center justify-center text-center hover:text-white font-raleway font-medium text-sm leading-6 py-4 uppercase';

  return (
    <div
      className={`fixed top-0 inset-x-0 z-[9999] transition-colors ease-in-out duration-300 ${
        windowScrollY > 10 ? 'bg-primary shadow-md' : 'bg-transparent'
      }`}
    >
      <BlockContainer outerClasses='!py-0 relative'>
        <div className='flex items-center justify-between'>
          <div className='mr-10'>
            <LogoWhite
              className='h-6 cursor-pointer'
              onClick={navigateToHome}
            />
          </div>
          <div className='flex gap-8'>
            <a
              className={`${menuClassName} ${
                window.location.pathname === `/${getCurrentLanguage}`
                  ? 'text-white'
                  : 'text-white/50'
              }`}
              href={`/${getCurrentLanguage}`}
            >
              {t('navigation.home')}
            </a>
            {/* eslint-disable-next-line prefer-arrow-callback */}
            {menuItems.map(function(menuItem) {
                let url = `/${menuItem.locale}${menuItem.url}`;
                if (menuItem.url.toLowerCase().startsWith('http://') || menuItem.url.toLowerCase().startsWith('https://')) {
                  url = menuItem.url;
                }

                return <a
                    key={menuItem.id}
                    className={`${menuClassName} ${
                        isActive(menuItem) ? 'text-white' : 'text-white/50'
                    }`}
                    href={`${url}`}
                >
                  {menuItem.title}
                </a>
            })}
            <LanguageSwitch/>
          </div>
        </div>
      </BlockContainer>
    </div>
  );
};
