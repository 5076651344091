import { z } from 'zod';

import ZSCBBanner from './contentBlocks/ZSCBBanner';
import ZSCBCard from './contentBlocks/ZSCBCard';
import ZSCBCardSlider from './contentBlocks/ZSCBCardSlider';
import ZSCBContent from './contentBlocks/ZSCBContent';
import ZSCBDevelopmentProcess from './contentBlocks/ZSCBDevelopmentProcess';
import ZSCBHotspot from './contentBlocks/ZSCBHotspot';
import ZSCBIFrame from './contentBlocks/ZSCBIFrame';
import ZSCBIconCardBlock from './contentBlocks/ZSCBIconCardBlock';
import ZSCBImageWithLink from './contentBlocks/ZSCBImageWithLink';
import ZSCBImageWithText from './contentBlocks/ZSCBImageWithText';
import ZSCBMultipleImageText from './contentBlocks/ZSCBMultipleImageText';
import ZSCBMultipleTextRows from './contentBlocks/ZSCBMultipleTextRows';
import ZSCBSliderSelector from './contentBlocks/ZSCBSliderSelector';
import ZSCBSplitContent from './contentBlocks/ZSCBSplitContent';
import ZSCBTeamAndLocations from './contentBlocks/ZSCBTeamAndLocations';
import ZSCBTeamwork from './contentBlocks/ZSCBTeamwork';
import ZSCBText from './contentBlocks/ZSCBText';
import ZSCBTextMedia from './contentBlocks/ZSCBTextMedia';
import ZSCBTextRow from './contentBlocks/ZSCBTextRow';
import ZSCBTimeline from './contentBlocks/ZSCBTimeline';
import ZSCBTimetable from './contentBlocks/ZSCBTimetable';
import ZSCBWysiwyg from './contentBlocks/ZSCBWysiwyg';

/**
 * MIND: ZSCB = Zod Sulu Content Block
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

export const ZSuluContentBlock = z.union([
  ZSCBBanner,
  ZSCBText,
  ZSCBTextMedia,
  ZSCBSplitContent,
  ZSCBCardSlider,
  ZSCBCard,
  ZSCBTextRow,
  ZSCBIconCardBlock,
  ZSCBImageWithText,
  ZSCBMultipleImageText,
  ZSCBMultipleTextRows,
  ZSCBImageWithLink,
  ZSCBWysiwyg,
  ZSCBDevelopmentProcess,
  ZSCBTeamAndLocations,
  ZSCBTeamwork,
  ZSCBTimeline,
  ZSCBSliderSelector,
  ZSCBIFrame,
  ZSCBTimetable,
]);

export type ISCBBanner = z.infer<typeof ZSCBBanner>;
export type ISCBText = z.infer<typeof ZSCBText>;
export type ISCBTextMedia = z.infer<typeof ZSCBTextMedia>;
export type ISCBSplitContent = z.infer<typeof ZSCBSplitContent>;
export type ISCBContent = z.infer<typeof ZSCBContent>;
export type ISCBCard = z.infer<typeof ZSCBCard>;
export type ISCBHotspot = z.infer<typeof ZSCBHotspot>;
export type ISCBIconCardBlock = z.infer<typeof ZSCBIconCardBlock>;
export type ISCBCardSlider = z.infer<typeof ZSCBCardSlider>;
export type ISCBSliderSelector = z.infer<typeof ZSCBSliderSelector>;
export type ISCBTextRow = z.infer<typeof ZSCBTextRow>;
export type ISCBImageWithText = z.infer<typeof ZSCBImageWithText>;
export type ISCBMultipleImageText = z.infer<typeof ZSCBMultipleImageText>;
export type ISCBImageWithLink = z.infer<typeof ZSCBImageWithLink>;
export type ISCBWysiwyg = z.infer<typeof ZSCBWysiwyg>;
export type ISuluContentBlock = z.infer<typeof ZSuluContentBlock>;
export type ISCBIFrame = z.infer<typeof ZSCBIFrame>;
export type ISCBTimetable = z.infer<typeof ZSCBTimetable>;
