import { INavigation } from 'types/interfaces/sulu/INavigation';

import getCurrentLanguage from './getCurrentLanguage';

export default async (): Promise<INavigation[]> => {
  const response = await fetch(`/${getCurrentLanguage}/api/navigations/main`);
  if (response.ok) {
    const jsonResponse = await response.json();
    // eslint-disable-next-line no-underscore-dangle
    return jsonResponse._embedded.items;
  }
  return [];
};
