import React from 'react';

import ButtonLink, { IButtonLink } from 'components/primitives/ButtonLink';

export interface ICardImage {
  image?: string;
  title: string;
  copy: string;
  button?: IButtonLink;
}

const CardImage = ({ image, title, copy, button }: ICardImage) => {
  const bgImageStyle = {
    paddingTop: '56.25%', // aspect ratio 16:9
    backgroundImage: `url(${image})`,
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className='bg-white shadow-md hover:shadow-card-hover flex flex-col h-full cursor-pointer transition-all ease-in-out duration-300'
      onClick={() => {
        window.location.href = button ? button.url : '';
      }}
    >
      {image && (
        <div
          className='bg-center bg-no-repeat bg-cover flex-2'
          style={bgImageStyle}
        />
      )}
      <div className='flex-1 px-10 pt-7 pb-9 flex-3'>
        <h3 className='text-center'>{title}</h3>
        <div
          className='cardImageDescription font-light mt-4'
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
      <div className='flex-1 flex items-end'>
        {button && <ButtonLink {...button} />}
      </div>
    </div>
  );
};

CardImage.defaultProps = {
  image: undefined,
  button: undefined,
};

export default CardImage;
