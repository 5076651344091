import React from 'react';

import { IAddress } from 'types/interfaces/IAddress';
import { ILink } from 'types/interfaces/ILink';

import { ILocation } from '../TeamAndLocations/Locations';

export interface IFooterCompany {
  name: string;
  locations: ILocation[];
}

export interface IFooterContact {
  title: string;
  address: IAddress;
}

export interface IFooterPrivacyPolicies {
  title: string;
  links: ILink[];
}

interface IFooter {
  company: IFooterCompany;
  contact: IFooterContact;
  privacyPolicies: IFooterPrivacyPolicies;
}

const Footer = ({ company, contact, privacyPolicies }: IFooter) => {
  const createLocations = () => {
    let order = 1;

    return company.locations.map(location => {
      order += 1;
      return (
        <div
          key={`${location.name}: ${location.address}`}
          /* purgecss order-2 order-3 */
          className={`order-${order} lg:order-none col-span-1 mt-1.5 lg:mt-0`}
        >
          <p className='font-bold uppercase'>{location.name}</p>
          <p dangerouslySetInnerHTML={{ __html: location.address }} />
        </div>
      );
    });
  };

  const createPrivacyPolicyLinks = () =>
    privacyPolicies.links.map(link => (
      <a
        key={`${link.name}: ${link.url}`}
        href={link.url}
        target={link.target || '_blank'}
        rel='noreferrer noopener'
        className='text-primary-text hover:text-primary text-base leading-7 font-light self-start no-underline'
      >
        {link.name}
      </a>
    ));

  return (
    <div className='bg-gray-lighter'>
      <div className='narrowWidth grid grid-cols-1 lg:grid-cols-4 lg:gap-5 px-10 lg:px-20 py-20 lg:pb-[5.5rem]'>
        {company && (
          <div className='order-1 lg:order-none lg:col-span-2 mb-2 lg:mb-0'>
            <h3 className='font-light'>{company.name}</h3>
          </div>
        )}
        {contact && (
          <div className='order-4 lg:order-none col-span-1 mt-8 mb-3 lg:my-0'>
            <h3 className='font-light'>{contact.title}</h3>
          </div>
        )}
        {privacyPolicies && (
          <div className='order-6 lg:order-none col-span-1 mt-8 mb-3 lg:my-0'>
            <h3 className='font-light'>{privacyPolicies.title}</h3>
          </div>
        )}
        {company && createLocations()}
        {contact && (
          <div className='order-5 lg:order-none col-span-1'>
            <p>{contact.address.name}</p>
            {contact.address.email && (
              <p>
                <a href={`mailto:${contact.address.email}`}>
                  {contact.address.email}
                </a>
              </p>
            )}
            {contact.address.phone && <p>{contact.address.phone}</p>}
            {contact.address.street && <p>{contact.address.street}</p>}
            {contact.address.zip && contact.address.city && (
              <p>
                {contact.address.zip} {contact.address.city}
              </p>
            )}
          </div>
        )}
        {privacyPolicies && (
          <div className='order-7 lg:order-none col-span-1 flex flex-col mb-2 lg:mb-0'>
            {createPrivacyPolicyLinks()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
