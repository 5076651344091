import React from 'react';
import { useTranslation } from 'react-i18next';

const TechCloud = () => {
  const { t } = useTranslation();
  return (
    <div className='text-center'>
      <h3 className='uppercase mb-9'>
        {t('components.banner.techCloud.weSupportYou')}
      </h3>
      <h3 className='font-light uppercase'>
        Java | PHP | React | Spring | Symfony | Zend | Shopgate | Magento |
        Azure | Proccess digitization | Scrum | Operating Services
      </h3>
    </div>
  );
};
export default TechCloud;
