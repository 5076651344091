import React, { Key, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { ESuluFormFields } from 'types/enums/sulu/EBackendData';
import { ISuluFormInput } from 'types/interfaces/sulu/IForm';

import ChipComponent from '../primitives/ChipComponent';
import { IChip } from '../primitives/ChipComponent/Chip';
import DropdownComponent from '../primitives/DropdownComponent';

/**
 * When adding an attachment to a file input, the name of the selected
 * file should be displayed.
 * To be able to identify the file input, we are using the propertyPath
 * which will be the key (see onChange method of Attachment input).
 */
interface IAttachmentDescription {
  [key: string]: string;
}

export default (
  formInput: ISuluFormInput,
  register: UseFormRegister<any>,
  key: Key,
  setSelectedChips?: React.Dispatch<React.SetStateAction<IChip[]>>,
  setSelectedOption?: React.Dispatch<React.SetStateAction<string>>,
): JSX.Element | null => {
  /**
   * Save the names of the selected attachments into a state so that we can update the inputs.
   */
  const [attachmentDescriptions, setAttachmentDescriptions] =
    useState<IAttachmentDescription>({});

  let inputElement: JSX.Element;

  // render nothing if the input does not exist
  if (!formInput) {
    return null;
  }

  const {
    label,
    required,
    type,
    property_path: propertyPath,
    attr: { widthNumber },
  } = formInput;

  const textInput = (
    <input
      {...register(propertyPath, { required: !!required })}
      placeholder={`${label}${required ? '*' : ''}`}
      className='focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary input mb-2 bg-primary-lighter text-primary-text px-5 py-1 font-light placeholder:text-primary-text w-full'
      type='text'
      required={!!required}
    />
  );

  switch (type) {
    case ESuluFormFields.EMAIL:
      inputElement = (
        <input
          {...register(propertyPath, { required: !!required })}
          placeholder={`${label}${required ? '*' : ''}`}
          className='focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary input mb-2 bg-primary-lighter text-primary-text px-5 py-1 font-light placeholder:text-primary-text w-full'
          type='email'
          required={!!required}
        />
      );
      break;
    case ESuluFormFields.TEXTAREA:
      inputElement = (
        <textarea
          {...register(propertyPath, { required: !!required })}
          required={!!required}
          placeholder={`${label}${required ? '*' : ''}`}
          className='focus-visible:outline focus-visible:outline-2 focus-visible:outline focus-visible:outline-primary flex-1 resize-none min-h-[11rem] w-full h-full bg-primary-lighter text-primary-text px-5 py-1 font-light placeholder:text-primary-text'
        />
      );
      break;
    case ESuluFormFields.CHECKBOX:
      inputElement = (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className='flex mx-4'>
          <input
            {...register(propertyPath, { required: !!required })}
            className='focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary checkbox w-10 h-10 mr-2 self-center bg-primary-lighter'
            type='checkbox'
            required={!!required}
            value='1'
          />
          <h5 className='font-light'>{label}</h5>
        </label>
      );
      break;
    case ESuluFormFields.TEXT:
      inputElement = textInput;
      break;
    case ESuluFormFields.TITLE:
      inputElement = textInput;
      break;
    case ESuluFormFields.FIRST_NAME:
      inputElement = textInput;
      break;
    case ESuluFormFields.LAST_NAME:
      inputElement = textInput;
      break;
    case ESuluFormFields.STREET:
      inputElement = textInput;
      break;
    case ESuluFormFields.CITY:
      inputElement = textInput;
      break;
    case ESuluFormFields.SALUTATION:
    case ESuluFormFields.DROPDOWN:
      if (setSelectedOption) {
        inputElement = (
          <DropdownComponent
            data={formInput}
            setSelectedOption={setSelectedOption}
          /> // setSelectedOption={setSelectedOption} />
        );
      } else {
        inputElement = <div />;
      }
      break;
    case ESuluFormFields.STATE:
      inputElement = textInput;
      break;
    case ESuluFormFields.CHECKBOX_MULTIPLE:
      if (setSelectedChips) {
        inputElement = (
          <ChipComponent data={formInput} setSelectedChips={setSelectedChips} />
        );
      } else {
        inputElement = <div />;
      }
      break;
    case ESuluFormFields.ATTACHMENT:
      inputElement = (
        <div className='relative h-36 rounded m-4 bg-primary text-primary-lighter no-underline hover:text-primary-lighter hover:bg-primary-hover transition-colors ease-in-out duration-300'>
          <div className='flex h-full items-center'>
            {attachmentDescriptions[propertyPath] ? (
              <div className='w-full'>
                <h3 className='text-primary-lightest text-center'>{label}</h3>
                <p className='mt-0 text-primary-lightest text-center'>
                  {attachmentDescriptions[propertyPath]}
                </p>
              </div>
            ) : (
              <h3 className='w-full text-primary-lightest text-center'>
                {label}
              </h3>
            )}
          </div>
          <input
            className='absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer'
            {...register(propertyPath, { required: !!required })}
            onChange={e => {
              /**
               * onChange we are checking if a new file has been selected
               * or if a file has been de-selected and update the state accordingly.
               */
              if (e.target.files?.length) {
                setAttachmentDescriptions({
                  ...attachmentDescriptions,
                  [propertyPath]: e.target.files[0].name,
                });
              } else {
                /**
                 * Mind the super fancyness as this operation defines two new variables:
                 * - `propertyPathSliced`: The property that will be removed
                 * - `attachmentDescriptionsSliced`: The rest of the attachmentDescriptions
                 * This way we do not have to mutate (=delete) the original object (`attachmentDescriptions`)!
                 */
                const {
                  [propertyPath]: propertyPathSliced,
                  ...attachmentDescriptionsSliced
                } = attachmentDescriptions;
                setAttachmentDescriptions(attachmentDescriptionsSliced);
              }
            }}
            type='file'
            required={!!required}
          />
        </div>
      );
      break;
    default:
      inputElement = (
        <p>
          Element <i>{type}</i> not found.
        </p>
      );
  }

  return (
    <div key={key} className={`col-span-12 lg:col-span-${widthNumber}`}>
      {inputElement}
    </div>
  );
};
