import React from 'react';

import { SplitContent } from 'components/blocks';
import BlockContainer from 'components/blocks/_blockContainer';
import JobRole from 'components/static/JobRole';

import {
  ESuluContentBlockBackgroundVariants,
  ESuluContentBlockSplitVariants,
} from 'types/enums/sulu/EBackendData';
import { ISPJob } from 'types/interfaces/sulu/IPages';

import suluApplyFormHandler from 'utils/sulu/applyFormHandler';
import suluSnippetHandler from 'utils/sulu/snippetHandler';

export default ({ content }: ISPJob) => (
  <>
    {content.header && suluSnippetHandler(content.header)}

    <BlockContainer
      backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHTEST}
    >
      <JobRole {...content} />
    </BlockContainer>
    {(content.this_is_us.length > 0 || content.and_thats_you.length > 0) && (
      <BlockContainer
        backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHT}
        outerClasses='!p-0'
        innerClasses='!p-0'
      >
        <SplitContent
          variant={ESuluContentBlockSplitVariants['50_50']}
          left={content.this_is_us.length ? [content.this_is_us[0]] : []}
          right={content.and_thats_you.length ? [content.and_thats_you[0]] : []}
        />
      </BlockContainer>
    )}
    <BlockContainer
      backgroundVariant={ESuluContentBlockBackgroundVariants.PRIMARY}
    >
      <div
        className='blockInner'
        dangerouslySetInnerHTML={{ __html: content.thats_what_we_offer }}
      />
    </BlockContainer>
    {!Array.isArray(content.other_positions) &&
      suluSnippetHandler(content.other_positions)}
    {content.apply_form && suluApplyFormHandler(content.apply_form)}
  </>
);
