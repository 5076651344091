import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import { ISSPageHeader } from 'types/interfaces/sulu/ISnippets';

export default ({ content }: ISSPageHeader) => {
  const { image, title, subtitle } = content;
  const bgImageStyle = {
    backgroundImage: `url(${image.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };
  return (
    <Parallax
      translateY={['0px', '200px']}
      shouldAlwaysCompleteAnimation
      className='relative theme-primary page-header z-[-1]'
    >
      <div className='pt-14' style={bgImageStyle}>
        <div className='min-h-full px-10 lg:px-20 text-center flex flex-col justify-between overflow-hidden'>
          <h1
            className='font-raleway text-5xl lg:text-h1 !leading-tight font-bold text-primary-text pt-10 pb-3 lg:pt-8 lg:pb-4'
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h3 className='font-light pt-3 lg:pt-4 pb-11 m-0'>{subtitle}</h3>
        </div>
      </div>
    </Parallax>
  );
};
