import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBTimtableContent = z.array(
  z.object({
    day: z.object({ name: z.string() }),
    description: z.string(),
    name: z.string(),
    slot: z.object({
      start: z.string(),
      end: z.string(),
    }),
    speaker: z.string(),
    track: z.object({
      icon: z.string(),
      name: z.string(),
    }),
  }),
);

const ZSCBTimetable = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TIMETABLE),
  timetable: z.array(ZSCBTimtableContent),
});

export default ZSCBTimetable;
