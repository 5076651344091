import { z } from 'zod';

import {
  ESuluCardSliderVariants,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

/**
 * Mind the IconCard can not (yet) be added as a standalone block.
 * It is currently only part of the SliderSelector schema / block.
 */
const ZIconCard = z.object({
  icon: ZSuluMedia.or(z.null()),
  title: z.string().or(z.null()),
  description_html: z.string().or(z.null()),
  button_link: z.string().or(z.null()),
  button_text: z.string().or(z.null()),
});

const ZSCBIconCardBlock = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.ICON_CARD_BLOCK),
  title: z.string().or(z.null()),
  variant: z.nativeEnum(ESuluCardSliderVariants).or(z.null()),
  icon_blocks: z.array(ZIconCard),
});

export default ZSCBIconCardBlock;
