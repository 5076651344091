import React from 'react';

import { ESuluContentBlockBackgroundVariants } from 'types/enums/sulu/EBackendData';

interface IBlockContainer {
  children: JSX.Element;
  hasNarrowWidth?: boolean;
  fullWidth?: boolean;
  outerClasses?: string;
  innerClasses?: string;
  backgroundVariant?: ESuluContentBlockBackgroundVariants | null;
}

const BlockContainer = ({
  children,
  hasNarrowWidth,
  fullWidth,
  outerClasses,
  innerClasses,
  backgroundVariant,
}: IBlockContainer) => (
  <div
    className={`blockOuter theme-${
      backgroundVariant || 'default'
    } ${outerClasses}`}
  >
    <div
      className={`${hasNarrowWidth ? 'narrowWidth' : ''} ${
        fullWidth ? 'fullWidth' : ''
      } 
      ${innerClasses} blockInner`}
    >
      {children}
    </div>
  </div>
);

BlockContainer.defaultProps = {
  hasNarrowWidth: false,
  fullWidth: false,
  outerClasses: '',
  innerClasses: '',
  backgroundVariant: ESuluContentBlockBackgroundVariants.DEFAULT,
};

export default BlockContainer;
