import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowScroll } from 'react-use';

import LogoWhite from 'assets/logos/logo_white.svg';

import LanguageSwitch from 'components/static/LanguageSwitch';

import { INavigation } from 'types/interfaces/sulu/INavigation';

import getCurrentLanguage from 'utils/sulu/getCurrentLanguage';

interface IMenu {
  menuItems: INavigation[];
}

const isActive = (menuItem: INavigation): boolean =>
  window.location.pathname === `/${menuItem.locale}${menuItem.url}`;

export default ({ menuItems }: IMenu) => {
  const { t } = useTranslation();
  const { y: windowScrollY } = useWindowScroll();
  const [showSidebar, setShowSidebar] = useState(false);

  const navigateToHome = () => {
    window.location.href = `/${getCurrentLanguage}`;
  };

  const menuClassName =
    'flex items-center justify-start text-left hover:text-white font-raleway font-medium text-sm leading-6 py-4 uppercase';

  return (
    <>
      <div
        className={`fixed top-0 inset-x-0 z-[9999] h-14 flex items-center justify-center text-white transition-colors ease-in-out duration-300 ${
          showSidebar
            ? 'bg-primary'
            : `${
                windowScrollY > 10 ? 'bg-primary shadow-md' : 'bg-transparent'
              }`
        }`}
      >
        <button
          type='button'
          onClick={() => setShowSidebar(!showSidebar)}
          className='absolute left-4 top-1/2 -translate-y-1/2 focus:outline-none'
        >
          <span className='sr-only'>Open main menu</span>
          <div className='flex flex-col w-8'>
            <span
              aria-hidden='true'
              className={`block h-1 w-full bg-white rounded-full transition duration-300 ease-in-out mb-1.5 ${
                showSidebar ? 'rotate-45 translate-y-2.5' : ''
              }`}
            />
            <span
              aria-hidden='true'
              className={`block h-1 w-full bg-white rounded-full transition duration-300 ease-in-out ${
                showSidebar ? 'opacity-0' : ''
              }`}
            />
            <span
              aria-hidden='true'
              className={`block h-1 w-full bg-white rounded-full transition duration-300 ease-in-out mt-1.5 ${
                showSidebar ? '-rotate-45 -translate-y-2.5' : ''
              }`}
            />
          </div>
        </button>
        <LogoWhite className='h-6 cursor-pointer' onClick={navigateToHome} />
      </div>

      {/* backdrop */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`fixed z-[9997] top-14 left-0 w-full h-full bg-black/50 ease-in-out duration-300 ${
          showSidebar ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
        onClick={() => setShowSidebar(false)}
      />
      <div
        className={`fixed z-[9998] top-0 left-0 pt-14 pr-10 pb-10 pl-5 w-72 max-w-full h-full overflow-auto bg-primary shadow-md ease-in-out duration-300 ${
          showSidebar ? 'translate-x-0 ' : '-translate-x-full'
        }`}
      >
        <div className='flex flex-col justify-between h-full'>
          <div className='flex flex-col'>
            <a
              className={`${menuClassName} ${
                window.location.pathname === `/${getCurrentLanguage}`
                  ? 'text-white'
                  : 'text-white/50'
              }`}
              href={`/${getCurrentLanguage}`}
              onClick={() => setShowSidebar(false)}
            >
              {t('navigation.home')}
            </a>
            {/* eslint-disable-next-line prefer-arrow-callback */}
            {menuItems.map(function(menuItem) {
              let url = `/${menuItem.locale}${menuItem.url}`;
              if (menuItem.url.toLowerCase().startsWith('http://') || menuItem.url.toLowerCase().startsWith('https://')) {
                url = menuItem.url;
              }

              return <a
                      key={menuItem.id}
                      className={`${menuClassName} ${
                          isActive(menuItem) ? 'text-white' : 'text-white/50'
                      }`}
                      href={`${url}`}
                      onClick={() => setShowSidebar(false)}
                  >
                    {menuItem.title}
                  </a>;
            })}
          </div>
          <div className='-ml-2'>
            <LanguageSwitch/>
          </div>
        </div>
      </div>
    </>
  );
};
