import React from 'react';
import { useTranslation } from 'react-i18next';

import { ISPJob } from 'types/interfaces/sulu/IPages';

export default ({
  job_name,
  job_level,
  employment_type,
  intro_html,
  text_left_html,
  text_right_html,
}: ISPJob['content']) => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className='text-center mb-4'>
        <span className='font-bold'>
          {t(`components.job.jobLevel.${job_level}`)}
        </span>{' '}
        | <span className='font-light'>{job_name}</span>
      </h1>
      <h2 className='text-center'>
        {t(`components.job.jobType.${employment_type}`)}
      </h2>
      <div
        className='mt-12 mb-6'
        dangerouslySetInnerHTML={{ __html: intro_html }}
      />
      <div className='grid md:grid-cols-2'>
        <div
          className='mb-6 md:mb-0 md:mr-6'
          dangerouslySetInnerHTML={{ __html: text_left_html }}
        />
        <div dangerouslySetInnerHTML={{ __html: text_right_html }} />
      </div>
    </div>
  );
};
