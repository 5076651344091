// By default, Klaro will load the config from  a global "klaroConfig" variable.
// You can change this by specifying the "data-config" attribute on your
// script take, e.g. like this:
// <script src="klaro.js" data-config="myConfigVariableName" />
const klaroConfig = {
  // With the 0.7.0 release we introduce a 'version' paramter that will make
  // it easier for us to keep configuration files backwards-compatible in the future.
  version: 1,

  // You can customize the ID of the DIV element that Klaro will create
  // when starting up. If undefined, Klaro will use 'klaro'.
  elementID: 'klaro',

  // You can override CSS style variables here. For IE11, Klaro will
  // dynamically inject the variables into the CSS. If you still consider
  // supporting IE9-10 (which you probably shouldn't) you need to use Klaro
  // with an external stylesheet as the dynamic replacement won't work there.
  styling: {
    theme: ['dark', 'right'],
    green1: '#04909f',
    blue1: '#04909f',
  },

  // Setting this to true will keep Klaro from automatically loading itself
  // when the page is being loaded.
  noAutoLoad: false,

  // Setting this to true will render the descriptions of the consent
  // modal and consent notice are HTML. Use with care.
  htmlTexts: true,

  // Setting 'embedded' to true will render the Klaro modal and notice without
  // the modal background, allowing you to e.g. embed them into a specific element
  // of your website, such as your privacy notice.
  embedded: false,

  // You can group services by their purpose in the modal. This is advisable
  // if you have a large number of services. Users can then enable or disable
  // entire groups of services instead of having to enable or disable every service.
  groupByPurpose: true,

  // How Klaro should store the user's preferences. It can be either 'cookie'
  // (the default) or 'localStorage'.
  storageMethod: 'cookie',

  storageName: 'klaroStorage',

  // You can customize the name of the cookie that Klaro uses for storing
  // user consent decisions. If undefined, Klaro will use 'klaro'.
  cookieName: 'klaro',

  // You can also set a custom expiration time for the Klaro cookie.
  // By default, it will expire after 120 days.
  cookieExpiresAfterDays: 365,

  // You can change to cookie domain for the consent manager itself.
  // Use this if you want to get consent once for multiple matching domains.
  // If undefined, Klaro will use the current domain.
  // cookieDomain: '.github.com',

  // You can change to cookie path for the consent manager itself.
  // Use this to restrict the cookie visibility to a specific path.
  // If undefined, Klaro will use '/' as cookie path.
  // cookiePath: '/',

  // Defines the default state for services (true=enabled by default).
  default: false,

  // If "mustConsent" is set to true, Klaro will directly display the consent
  // manager modal and not allow the user to close it before having actively
  // consented or declines the use of third-party services.
  mustConsent: false,

  // Show "accept all" to accept all services instead of "ok" that only accepts
  // required and "default: true" services
  acceptAll: true,

  // replace "decline" with cookie manager modal
  hideDeclineAll: false,

  // hide "learnMore" link
  hideLearnMore: false,

  // show cookie notice as modal
  noticeAsModal: false,

  // You can also remove the 'Realized with Klaro!' text in the consent modal.
  // Please don't do this! We provide Klaro as a free open source tool.
  // Placing a link to our website helps us spread the word about it,
  // which ultimately enables us to make Klaro! better for everyone.
  // So please be fair and keep the link enabled. Thanks :)
  // disablePoweredBy: true,

  // you can specify an additional class (or classes) that will be added to the Klaro `div`
  // additionalClass: 'my-klaro',

  // You can define the UI language directly here. If undefined, Klaro will
  // use the value given in the global "lang" variable. If that does
  // not exist, it will use the value given in the "lang" attribute of your
  // HTML tag. If that also doesn't exist, it will use 'en'.
  // lang: 'de',

  // You can overwrite existing translations and add translations for your
  // service descriptions and purposes. See `src/translations/` for a full
  // list of translations that can be overwritten:
  // https://github.com/KIProtect/klaro/tree/master/src/translations

  // Example config that shows how to overwrite translations:
  // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
  translations: {
    // translationsed defined under the 'zz' language code act as default
    // translations.
    // zz: {
    //     privacyPolicyUrl: '/#privacy',
    // },
    // If you erase the "consentModal" translations, Klaro will use the
    // bundled translations.

    de: {
      consentModal: {
        title: 'Diese Website verwendet Cookies',
        description:
            'Diese Website verwendet Cookies und ähnliche Funktionen zur Einbindung von Inhalten, externen Diensten und Elementen von Drittanbietern, zur Benutzerverfolgung und zur Einbindung von sozialen Medien.<br>Ihre Einwilligung ist stets freiwillig, für die Nutzung unserer Website nicht erforderlich und kann dies auch sein jederzeit abgelehnt oder widerrufen werden. Um mehr zu erfahren, lesen Sie bitte unsere <a href="https://www.bitexpert.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
      },
      consentNotice: {
        title: 'Diese Website verwendet Cookies',
        description:
            'Diese Website verwendet Cookies und ähnliche Funktionen zur Einbindung von Inhalten, externen Diensten und Elementen von Drittanbietern, zur Benutzerverfolgung und zur Einbindung von sozialen Medien.<br>Ihre Einwilligung ist stets freiwillig, für die Nutzung unserer Website nicht erforderlich und kann dies auch sein jederzeit abgelehnt oder widerrufen werden. Um mehr zu erfahren, lesen Sie bitte unsere <a href="https://www.bitexpert.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
      },
      decline: 'Alle ablehnen',
      ok: 'Alle akzeptieren',
    },
    en: {
      consentModal: {
        title: 'This website uses cookies',
        description:
          'This website uses cookies and similar functions to integrate content, external services and elements from third parties, user tracking, and the integration of social media.<br>Your consent is always voluntary, not required for the use of our website, and can be rejected or revoked at any time. To learn more, please read our <a href="https://www.bitexpert.de/en_US/datenschutz/" target="_blank" rel="noopener noreferrer">privacy policy</a>.',
      },
      consentNotice: {
        title: 'This website uses cookies',
        description:
          'This website uses cookies and similar functions to integrate content, external services and elements from third parties, user tracking, and the integration of social media.<br>Your consent is always voluntary, not required for the use of our website, and can be rejected or revoked at any time. To learn more, please read our <a href="https://www.bitexpert.de/en_US/datenschutz/" target="_blank" rel="noopener noreferrer">privacy policy</a>.',
      },
      decline: 'Decline all',
      ok: 'Accept all',
    },
  },

  // This is a list of third-party services that Klaro will manage for you.
  apps: [
    // {
    //     name: 'twitter',
    //     default: false,
    //     contextualConsentOnly: true,
    //     purposes: ['marketing'],
    // },
    // {
    //     name: 'youtube',
    //     default: true,
    //     purposes: ['marketing'],
    // },
    {
      name: 'OpenStreetmap',
      default: true,
      purposes: ['showMaps'],
      callback(consent) {
        if (consent) {
          // eslint-disable-next-line no-undef
          document.getElementById('mapImage').style.display = 'none';
          // eslint-disable-next-line no-undef
          document.getElementById('map').style.display = 'block';
        } else {
          // eslint-disable-next-line no-undef
          document.getElementById('mapImage').style.display = 'block';
          // eslint-disable-next-line no-undef
          document.getElementById('map').style.display = 'none';
        }
      },
      required: false,

      // If "optOut" is set to true, Klaro will load this service even before
      // the user gave explicit consent.
      // We recommend always leaving this "false".
      optOut: false,

      // If "onlyOnce" is set to true, the service will only be executed
      // once regardless how often the user toggles it on and off.
      onlyOnce: true,
    },
    {
      // Each service should have a unique (and short) name.
      name: 'Matomo',

      // If "default" is set to true, the service will be enabled by default
      // Overwrites global "default" setting.
      // We recommend leaving this to "false" for services that collect
      // personal information.
      default: false,

      // The title of you service as listed in the consent modal.
      title: 'Matomo',

      // The purpose(s) of this service. Will be listed on the consent notice.
      // Do not forget to add translations for all purposes you list here.
      purposes: ['analytics'],

      // A list of regex expressions or strings giving the names of
      // cookies set by this service. If the user withdraws consent for a
      // given service, Klaro will then automatically delete all matching
      // cookies.
      cookies: [
        //     // you can also explicitly provide a path and a domain for
        //     // a given cookie. This is necessary if you have services that
        //     // set cookies for a path that is not "/" or a domain that
        //     // is not the current domain. If you do not set these values
        //     // properly, the cookie can't be deleted by Klaro
        //     // (there is no way to access the path or domain of a cookie in JS)
        //     // Notice that it is not possible to delete cookies that were set
        //     // on a third-party domain! See the note at mdn:
        //     // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-cookie_domain
        [/^_pk_.*$/, '/', 'www.unkonf.de'], // for the production version
        [/^_pk_.*$/, '/', 'unkonf.staging.bitexpert.de'], // for the local version
        [/^_pk_.*$/, '/', 'localhost'], // for the local version
        'piwik_ignore',
      ],

      // An optional callback function that will be called each time
      // the consent state for the service changes (true=consented). Passes
      // the `service` config as the second parameter as well.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callback(consent, service) {
        // eslint-disable-next-line no-undef
        if (!window.SULU_ANALYTICS) {
          return;
        }

        // window.dataLayer = window.dataLayer || [];
        if (consent) {
          // eslint-disable-next-line no-undef
          window.enableTracking = 1;
          // eslint-disable-next-line no-undef,no-underscore-dangle,no-multi-assign,@typescript-eslint/naming-convention
          const _paq = (window._paq = window._paq || []);
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          // eslint-disable-next-line func-names
          (function () {
            // eslint-disable-next-line no-undef
            const u = `//${window.SULU_ANALYTICS.url}/`;
            _paq.push(['setTrackerUrl', `${u}matomo.php`]);
            // eslint-disable-next-line no-undef
            _paq.push(['setSiteId', window.SULU_ANALYTICS.siteId || '1']);
            // eslint-disable-next-line no-undef
            const d = document;
            const g = d.createElement('script');
            const s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript';
            g.async = true;
            g.src = `${u}matomo.js`;
            s.parentNode.insertBefore(g, s);
          })();
        } else {
          // eslint-disable-next-line no-undef
          window.enableTracking = false;
        }
      },

      // If "required" is set to true, Klaro will not allow this service to
      // be disabled by the user.
      required: false,

      // If "optOut" is set to true, Klaro will load this service even before
      // the user gave explicit consent.
      // We recommend always leaving this "false".
      optOut: false,

      // If "onlyOnce" is set to true, the service will only be executed
      // once regardless how often the user toggles it on and off.
      onlyOnce: true,
    },

    // The services will appear in the modal in the same order as defined here.
    //         {
    //             name: 'inlineTracker',
    //             title: 'Inline Tracker',
    //             purposes: ['analytics'],
    //             cookies: ['inline-tracker'],
    //             optOut: false,
    //         },
    //         {
    //             name: 'externalTracker',
    //             title: 'External Tracker',
    //             purposes: ['analytics', 'security'],
    //             cookies: ['external-tracker'],
    //         },
    //         {
    //             name: 'intercom',
    //             title: 'Intercom',
    //             default: true,
    //             purposes: ['livechat'],
    //         },
    //         {
    //             name: 'mouseflow',
    //             title: 'Mouseflow',
    //             purposes: ['analytics'],
    //         },
    //         {
    //             name: 'adsense',
    //             // if you omit the title here Klaro will try to look it up in the
    //             // translations
    //             //title: 'Google AdSense',
    //             purposes: ['advertising'],
    //         },
    //         {
    //             name: 'camera',
    //             title: 'Surveillance Camera',
    //             purposes: ['security'],
    //         },
    // /*        {
    //             name: 'googleFonts',
    //             title: 'Google Fonts',
    //             purposes: ['styling'],
    //         },*/
    //         {
    //             name: 'cloudflare',
    //             title: 'Cloudflare',
    //             purposes: ['security'],
    //             required: true,
    //         },
  ],
};

export default klaroConfig;
