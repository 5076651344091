import React from 'react';

import ButtonLink from 'components/primitives/ButtonLink';

import { ISCBImageWithText } from 'types/interfaces/sulu/IContentBlocks';

interface IImageWithText extends ISCBImageWithText {
  orientation: 'left' | 'right';
}

export default ({
  image,
  image_title,
  image_description,
  orientation,
  button_text,
  button_link,
}: IImageWithText) => {
  const bgImageStyle = {
    paddingTop: '56.25%', // aspect ratio 16:9
    backgroundImage: `url(${image.url})`,
  } as React.CSSProperties;

  return (
    <div className='grid md:grid-cols-2'>
      <div
        className={`py-14 pl-28 pr-20 order-2 ${
          orientation !== 'right' ? 'md:order-none' : ''
        }`}
      >
        {image_title && <h3>{image_title}</h3>}
        {image_description && (
          <h3
            className='font-light mt-8'
            dangerouslySetInnerHTML={{ __html: image_description }}
          />
        )}
        {button_text && button_link && (
          <div className='lg:w-3/4 mt-11'>
            <ButtonLink label={button_text} url={button_link} />
          </div>
        )}
      </div>
      <div
        className='order-1 md:order-none bg-cover bg-center bg-no-repeat'
        style={bgImageStyle}
      />
    </div>
  );
};
