import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBIFrame = ZSCBBase.omit({
  background_variant: true,
}).extend({
  type: z.literal(ESuluContentBlockTypes.IFRAME),
  url: z.string(),
});

export default ZSCBIFrame;
