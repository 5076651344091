import React from 'react';

import { ISCBImageWithLink } from 'types/interfaces/sulu/IContentBlocks';

export default ({ media, link }: ISCBImageWithLink) =>
  link ? (
    <a href={link} className='inline-block'>
      <img src={media.url} alt={media.name} />
    </a>
  ) : (
    <img src={media.url} alt={media.name} />
  );
