import React from 'react';

import { ISCBTimetable } from 'types/interfaces/sulu/IContentBlocks';

export default ({ timetable }: ISCBTimetable) => {
  const data = timetable[0];
  const displayedTimeSlots = new Map();

  /* We receive the data in an array containing each entry of the timetable.
   * We don't display each time the timeSlots, only when they are different.
   *
   * The following function creates a map and forces the frontend to not display
   * same timeSlot again.
   */
  const shouldDisplayTimeSlot = (start: string, end: string) => {
    if (displayedTimeSlots.get(`${start}-${end}`) === 1) {
      return false;
    }
    displayedTimeSlots.set(`${start}-${end}`, 1);
    return true;
  };

  return (
    <>
      {data.map(timeEntry => (
        <div key={`${timeEntry.name}-${timeEntry.speaker}`}>
          {shouldDisplayTimeSlot(timeEntry.slot.start, timeEntry.slot.end) && (
            <div
              className='bg-gray-light pl-2 py-1'
              id={`${timeEntry.slot.start}-${timeEntry.slot.end}`}
            >
              <h4 className='text-white'>{`${timeEntry.slot.start} - ${timeEntry.slot.end}`}</h4>
            </div>
          )}
          <div className='flex flex-row py-2'>
            <div className='flex bg-primary-light bg-center bg-no-repeat w-36 h-36'>
              <img
                className='p-8'
                alt='track-icon'
                src={`${timeEntry.track.icon}`}
              />
            </div>
            <div className='flex flex-1 flex-col ml-4 justify-center'>
              <p className='flex items-end text-gray-light'>
                {timeEntry.track.name}
              </p>
              <h2 className='flex items-center uppercase text-primary font-medium'>
                {timeEntry.name}
              </h2>
              <h4 className='flex items-start font-light text-gray-light'>
                {timeEntry.speaker}
              </h4>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
