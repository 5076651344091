export enum ESuluDataTypes {
  PAGE = 'page',
  SNIPPET = 'snippet',
}

export enum ESuluPageTypes {
  DEFAULT = 'default',
  JOB = 'job',
  PROJECT = 'project',
  PROJECTS_ARTICLES = 'projects_articles',
}

export enum ESuluSnippetTypes {
  ABOUT_CUSTOMER = 'about_customer',
  CARD_SLIDER = 'card_slider',
  PAGE_HEADER = 'page_header',
}

// see translation files
export enum ESuluPageJobLevel {
  JUNIOR = 'junior',
  SPECIALIST = 'specialist',
  SENIOR = 'senior',
}

// see translation files
export enum ESuluPageJobType {
  FULLTIME = 'fulltime',
  PARTTIME = 'parttime',
}

export enum ESuluPageProjectTechnologies {
  REACT = 'react',
  PHP = 'php',
  JAVA = 'java',
}

export enum ESuluContentBlockTypes {
  PAGE = 'page',
  BANNER = 'banner',
  TEXT = 'text',
  TEXT_MEDIA = 'text_media',
  SPLIT_CONTENT = 'split_content',
  CARD = 'card',
  CARD_SLIDER = 'card_slider',
  SLIDER_SELECTOR = 'slider_selector',
  ICON_CARD_BLOCK = 'icon_card_block',
  MULTIPLE_TEXT_ROWS = 'multiple_text_rows',
  TEXT_ROW = 'text_row',
  MULTIPLE_IMAGE_TEXT = 'multiple_image_text',
  IMAGE_WITH_TEXT = 'image_with_text',
  IMAGE_WITH_LINK = 'image_with_link',
  WYSIWYG = 'wysiwyg',
  FORM = 'form',
  DEVELOPMENT_PROCESS = 'development_process',
  TEAM_AND_LOCATIONS = 'teams_location',
  TEAMWORK = 'teamwork',
  TIMELINE = 'timeline',
  IFRAME = 'iframe',
  TIMETABLE = 'timetable',
}

export enum ESuluContentBlockBackgroundVariants {
  PRIMARY = 'primary',
  LIGHT = 'light',
  LIGHTER = 'lighter',
  LIGHTEST = 'lightest',
  PRIMARY_GRADIENT = 'primary_gradient',
  DEFAULT = 'default', // mind: backend sends `null` instead of `default`
}

export enum ESuluContentBlockTextMediaOrientations {
  DEFAULT = 'default',
  MEDIA_TEXT = 'media_text',
  TEXT_MEDIA = 'text_media',
}

export enum ESuluContentBlockTextSizes {
  LARGE = 'large',
}

export enum ESuluContentBlockSplitVariants {
  '50_50' = '50_50',
  '30_70' = '30_70',
  '70_30' = '70_30',
}

export enum ESuluCardSliderVariants {
  DEFAULT = '',
  '50_50' = '50_50',
  DOTS = 'dots',
}

export enum ESuluSettingsScheduleTypes {
  WEEKLY = 'weekly',
  FIXED = 'fixed',
}

export enum ESuluSettingsScheduleWeekDays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum ESuluMediaTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
}

export enum ESuluLanguages {
  'EN' = 'en',
  'DE' = 'de',
}

export enum ESuluFormWidths {
  FULL = 'full',
  HALF = 'half',
  ONE_QUARTER = 'one-quarter',
  THREE_QUARTERS = 'three-quarters',
  ONE_THIRD = 'one-third',
  TWO_THIRDS = 'two-thirds',
  ONE_SIXTH = 'one-sixth',
  FIVE_SIXTHS = 'five-sixths',
}

export enum ESuluFormFields {
  TEXT = 'text',
  EMAIL = 'email',
  CHECKBOX_MULTIPLE = 'checkboxMultiple',
  TEXTAREA = 'textarea',
  DROPDOWN = 'dropdown',
  TITLE = 'title',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  STREET = 'street',
  SALUTATION = 'salutation',
  CITY = 'city',
  STATE = 'state',
  CHECKBOX = 'checkbox',
  ATTACHMENT = 'attachment',
  SUBMIT = 'submit',
}

export enum ESuluFormMetaFields {
  LOCALE = 'locale',
  TYPE = 'type',
  TYPE_ID = 'typeId',
  FORM_ID = 'formId',
  FORM_NAME = 'formName',
  CHECKSUM = 'checksum',
}

export enum ESuluHotspotType {
  BASIC = 'basic',
}

export enum ESuluHotspotTypes {
  CIRCLE = 'circle',
  POINT = 'point',
  RECTANGLE = 'rectangle',
}
