import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Vivus from 'vivus';

import CoreTechnologyIcon from 'assets/icons/CoreTechnology.svg';
import InitiationIcon from 'assets/icons/Initiation.svg';
import RuntimeIcon from 'assets/icons/Runtime.svg';

import BlockContainer from 'components/blocks/_blockContainer';
import LogoWall from 'components/primitives/LogoWall';
import InfoBannerSlider, {
  IInfoBannerSlider,
} from 'components/static/InfoBannerSlider';
import ProjectIntro from 'components/static/ProjectIntro';

import { ESuluContentBlockBackgroundVariants } from 'types/enums/sulu/EBackendData';
import { ISPProject } from 'types/interfaces/sulu/IPages';

import suluBlockHandler from 'utils/sulu/blockHandler';
import suluSnippetHandler from 'utils/sulu/snippetHandler';

export default ({ content }: ISPProject) => {
  const { t } = useTranslation();

  const InitiationIconRef = useRef(
    <InitiationIcon
      id='initiation-icon'
      className='text-primary m-auto w-32'
    />,
  );
  const RuntimeIconRef = useRef(
    <RuntimeIcon id='runtime-icon' className='text-primary m-auto w-32' />,
  );
  const CoreTechnologyIconRef = useRef(
    <CoreTechnologyIcon
      id='core-technology-icon'
      className='text-primary m-auto w-32'
    />,
  );

  const generateInfoBannerItems = (): IInfoBannerSlider['items'] => {
    const infoBannerItems: IInfoBannerSlider['items'] = [];
    if (content.initiation_date) {
      infoBannerItems.push({
        icon: InitiationIconRef.current,
        title: t('components.project.banner.initiation'),
        copy: content.initiation_date,
      });
    }
    if (content.runtime_weeks) {
      infoBannerItems.push({
        icon: RuntimeIconRef.current,
        title: t('components.project.banner.runtime'),
        copy: `${content.runtime_weeks} ${t(
          'components.project.banner.weeks',
        )}`,
      });
    }
    if (content.core_technologies) {
      infoBannerItems.push({
        icon: CoreTechnologyIconRef.current,
        title: t('components.project.banner.coreTechnology'),
        copy: content.core_technologies,
      });
    }
    return infoBannerItems;
  };

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Vivus('initiation-icon', {
      duration: 200,
    });
    // eslint-disable-next-line no-new
    new Vivus('runtime-icon', {
      duration: 200,
    });
    // eslint-disable-next-line no-new
    new Vivus('core-technology-icon', {
      duration: 200,
    });
  }, []);

  return (
    <>
      {content.header && suluSnippetHandler(content.header)}

      <BlockContainer
        backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHTEST}
      >
        <ProjectIntro
          customer_name={content.customer_name}
          intro_html={content.intro_html}
          intro_media={content.intro_media}
          project_name={content.project_name}
        />
      </BlockContainer>

      <BlockContainer
        backgroundVariant={ESuluContentBlockBackgroundVariants.PRIMARY}
      >
        <InfoBannerSlider items={generateInfoBannerItems()} />
      </BlockContainer>

      {content.content_blocks.map((block, i) => suluBlockHandler(block, i))}

      <BlockContainer
        backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHT}
      >
        <div className='narrowWidth'>
          <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div className='lg:px-20'>
              <h2 className='mb-4'>{t('components.project.solution')}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: content.text_left_html }}
              />
            </div>
            <div className='mt-7 lg:mt-0 lg:px-20'>
              <h2 className='mb-4'>{t('components.project.result')}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: content.text_right_html }}
              />
            </div>
            {content.project_media && content.project_media.isImage && (
              <img
                className='mx-auto mt-10 md:w-3/4 lg:col-span-2 lg:w-1/2'
                src={content.project_media.url}
                alt={content.project_media.title}
              />
            )}
            {content.project_media && content.project_media.isVideo && (
              <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  className='mx-auto mt-10 md:w-3/4 lg:col-span-2 lg:w-1/2'
                  src={content.project_media.url}
                />
              </>
            )}
          </div>
        </div>
      </BlockContainer>

      {content.about && suluSnippetHandler(content.about)}

      <BlockContainer
        backgroundVariant={ESuluContentBlockBackgroundVariants.PRIMARY}
      >
        <LogoWall
          logoWallItems={content.more_customers}
          title={t('components.logoWall.title')}
        />
      </BlockContainer>
    </>
  );
};
