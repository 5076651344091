import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'style.css';

// 49.522998464186784, 8.465538868995274 // bitExpert DE
// 44.42311389038237, 26.093323540645823 // bitExpert RO
const Map = ({ latLng, id }: { latLng: LatLngExpression; id: string }) => (
  <MapContainer
    id={id}
    className='w-full h-64 md:h-96 lg:h-[40rem] max-h-screen'
    center={latLng}
    zoom={12}
    scrollWheelZoom={false}
    dragging
    touchZoom
  >
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    />
    <Marker
      position={latLng}
      icon={L.icon({
        iconUrl: '/assets/map/marker-icon.png',
        iconRetinaUrl: '/assets/map/marker-icon-2x.png',
        shadowUrl: undefined,
        shadowRetinaUrl: undefined,
      })}
    />
  </MapContainer>
);

export default Map;
