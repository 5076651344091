import React from 'react';

import CardImage from 'components/primitives/CardImage';

import { ISCBContent } from 'types/interfaces/sulu/IContentBlocks';

import './Card.css';

const Card = ({
  title,
  url,
  card_image,
  card_title,
  card_description_html,
  card_button_text,
}: ISCBContent) =>
  card_image && card_title && card_description_html && card_button_text ? (
    <CardImage
      copy={card_description_html || ''}
      image={card_image?.url}
      title={title || ''}
      button={{
        label: card_button_text,
        url: url || '',
      }}
    />
  ) : (
    <div />
  );

export default Card;
