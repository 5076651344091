import React from 'react';

import { Card } from 'components/blocks';
import BlockContainer from 'components/blocks/_blockContainer';

import { ESuluContentBlockBackgroundVariants } from 'types/enums/sulu/EBackendData';
import { ISPProjectsArticles } from 'types/interfaces/sulu/IPages';

import suluContactFormHandler from 'utils/sulu/contactFormHandler';
import suluSnippetHandler from 'utils/sulu/snippetHandler';

export default ({ content }: ISPProjectsArticles) => (
  <>
    {content.header && suluSnippetHandler(content.header)}

    <BlockContainer
      backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHTEST}
      innerClasses='!max-w-full'
    >
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {content.content_blocks.map(card => (
          <Card key={card.id} {...card.content} />
        ))}
      </div>
    </BlockContainer>
    {content.contact_form && suluContactFormHandler(content.contact_form)}
  </>
);
